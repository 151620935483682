/* eslint no-param-reassign: "error" */
import axios from 'axios';
import { LoaderStore } from 'mph-helper';
import i18n from '@/i18n';

export const baseURL = {
  api: '/formapi',
};

export const http = axios.create({
  baseURL: baseURL.api,
  headers: {
    Pragma: 'no-cache',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

http.interceptors.request.use((config) => {
  LoaderStore.commit('startLoading');
  config.headers['x-selected-language'] = i18n.global.locale;
  return config;
}, (error) => {
  LoaderStore.commit('endLoading');
  return Promise.reject(error);
});

http.interceptors.response.use((response) => {
  LoaderStore.commit('endLoading');
  return response;
}, (error) => {
  LoaderStore.commit('endLoading');
  return Promise.reject(error);
});

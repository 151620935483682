import { NavigationGuardNext, RouteLocation } from 'vue-router';

export default {
  components: {
    header: () => import(/* webpackChunkName: "form" */ '@/components/app-header/index.vue'),
    default: () => import(/* webpackChunkName: "form" */ '@/features/form/index.vue'),
    footer: () => import(/* webpackChunkName: "form" */ '@/components/app-footer/index.vue'),
  },
  name: 'form',
  path: 'form/:formCode?',
  beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
    if (to.params.formCode) {
      next();
    } else {
      window.location.href = process.env.VUE_APP_REDIRECT_LINK as string;
    }
  },
  meta: {
    title: 'Form.Title',
  },
};

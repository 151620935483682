<template>
  <app-locale>
    <el-container
      :class="$style.component"
      direction="vertical"
    >
      <router-view name="header"></router-view>
      <router-view v-loading.fullscreen.lock="isLoading"></router-view>
      <router-view name="footer"></router-view>
    </el-container>
  </app-locale>
</template>
<script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { LoaderStore } from 'mph-helper';
  import AppLocale from '@/components/app-locale/index.vue';

  export default defineComponent({
    setup() {
      const isLoading = computed(() => LoaderStore.getters.isLoading);
      return {
        isLoading,
      };
    },
    components: {
      AppLocale,
    },
  });
</script>
<style lang="scss" module>
  .component {
    height: 100vh;
    overflow: auto;
  }
</style>

<template>
  <router-view>
    <el-config-provider
      :locale="locale[$route.params.locale as string] || locale.fr"
      size="large"
    >
      <slot></slot>
    </el-config-provider>
  </router-view>
</template>
<script lang="ts">
  import { defineComponent } from 'vue';
  import en from 'element-plus/dist/locale/en.mjs';
  import fr from 'element-plus/dist/locale/fr.mjs';

  export default defineComponent({
    setup() {
      return {
        locale: {
          en,
          fr,
        } as { [key: string]: object },
      };
    },
  });
</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_app_locale = _resolveComponent("app-locale")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_app_locale, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_container, {
        class: _normalizeClass(_ctx.$style.component),
        direction: "vertical"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, { name: "header" }),
          _withDirectives(_createVNode(_component_router_view, null, null, 512), [
            [
              _directive_loading,
              _ctx.isLoading,
              void 0,
              {
                fullscreen: true,
                lock: true
              }
            ]
          ]),
          _createVNode(_component_router_view, { name: "footer" })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}
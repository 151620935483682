import { createStore, Module } from 'vuex';
import { RenditionsStore } from 'mph-helper';
import i18n from '@/i18n';
import { TRootState } from '@/store/type';
import { RenditionsService } from '@/common/services/renditions.service';

export default createStore({
  actions: {
    getRenditions({ dispatch }) {
      return dispatch(`${RenditionsStore.name}/getRenditions`, {
        action: RenditionsService.getRenditions,
        i18n,
      });
    },
  },
  modules: {
    [RenditionsStore.name]: RenditionsStore.module as Module<undefined, TRootState>,
  },
});

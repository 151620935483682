import { createApp } from 'vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import '@/styles/index.scss';
import ElementPlus from 'element-plus';
import { ArrowDown, Back, Delete, EditPen, Plus, WarningFilled } from '@element-plus/icons-vue';
import { HelperFormSchema, HelperLink } from 'mph-helper';
import App from '@/App.vue';
import store from '@/store';
import i18n from '@/i18n';
import router from '@/router';

export const app = createApp(App);

const ElementPlusIcons = {
  'icon-arrow-down': ArrowDown,
  'icon-back': Back,
  'icon-delete': Delete,
  'icon-edit-pen': EditPen,
  'icon-plus': Plus,
  'icon-warning-filled': WarningFilled,
};

Object
  .entries(ElementPlusIcons)
  .forEach(([key, value]) => {
    app.component(key, value);
  });

app.use(ElementPlus);
app.use(HelperFormSchema);
app.use(HelperLink);
app.use(VueQueryPlugin, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  },
});
app.use(store);
app.use(i18n);
app.use(router);
app.mount('#app');

import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_config_provider = _resolveComponent("el-config-provider")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_config_provider, {
        locale: _ctx.locale[_ctx.$route.params.locale as string] || _ctx.locale.fr,
        size: "large"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["locale"])
    ]),
    _: 3
  }))
}
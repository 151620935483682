import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized } from 'vue-router';
import { isEmpty } from 'lodash';
import { LocaleI18n } from 'mph-helper';
import i18n from '@/i18n';
import Store from '@/store';
import Form from '@/features/form';

function setTitleAndMetaTags(payload: RouteLocationNormalized) {
  const defaultTitle = 'My Protection Hub';
  const localeTitle = payload.meta?.title as string;
  document.title = (localeTitle ? `${i18n.global.t(localeTitle)} - ${defaultTitle}` : defaultTitle) as string;
  const metaTags = [{
    property: 'og:locale',
    content: i18n.global.locale,
  }, {
    property: 'og:title',
    content: document.title,
  }, {
    property: 'og:url',
    content: `${window.location.host}${payload.fullPath}`,
  }];
  const metaElements = Array.from(document.querySelectorAll('[vue-meta-tag]')) as Array<HTMLElement>;
  metaElements.forEach((item: HTMLElement) => {
    item.parentNode?.removeChild(item);
  });
  metaTags.forEach((item: { [key: string]: string }) => {
    const tag = document.createElement('meta');
    const keys = Object.keys(item) as Array<string>;
    keys.forEach((key: string) => {
      tag.setAttribute(key, item[key]);
    });
    tag.setAttribute('vue-meta-tag', '');
    document.head.appendChild(tag);
  });
}

const routes: Array<RouteRecordRaw> = [{
  path: '/:locale(en)?',
  name: 'locale',
  components: {},
  children: [
    {
      path: '',
      name: 'home',
      redirect: { name: 'form' },
    },
    Form,
    {
      path: ':pathMatch(.*)*',
      name: 'not-found',
      components: {},
      beforeEnter(): void {
        window.location.href = process.env.VUE_APP_REDIRECT_LINK as string;
      },
    },
  ],
}];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const localeUrl = to.params.locale as string;
  const locale = LocaleI18n[localeUrl] || LocaleI18n.fr;
  if (locale !== i18n.global.locale) {
    i18n.global.locale = locale;
  }
  if (isEmpty(i18n.global.messages[locale]) && process.env.NODE_ENV === 'production') {
    try {
      await Store.dispatch('getRenditions');
    } catch (error) {
      window.location.href = process.env.VUE_APP_REDIRECT_LINK as string;
      throw error;
    }
  }
  setTitleAndMetaTags(to);
  next();
});

export default router;
